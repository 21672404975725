import { BlogMenuEntity, MainMenuEntity, PromoEntity } from '@/gql/generated/graphql'
import clsx from 'clsx'
import React, { Fragment, useEffect } from 'react'
import PromoBanner from '../body/PromoBanner'
import ResponsiveImage from '../core/ResponsiveImage'
import LanguageSwitcher from './LanguageSwitcher'
import MobileMenu from './MobileMenu'
import ProductSignInWidget from './ProductSignInWidget'
import Sticky from "react-stickynode";
import { NEXT_PUBLIC_CDN_URL } from '@/env'
import { LogoJsonLd } from 'next-seo'

type Props = {
  menu: MainMenuEntity | BlogMenuEntity
  hideLocales?: boolean
  isWhiteTheme?: boolean
  notSticky?: boolean
  promos: PromoEntity[]
}

export default function MainMenu(props: Props) {
  
  return (
    <Sticky enabled={!props.notSticky} top={0} activeClass="stickyHeaderOpen" className='absolute'>
      {props.promos.map((promo, index) => (
        <Fragment key={promo.id}>{index === 0 && <PromoBanner promo={promo} />}</Fragment>
      ))}
      <header id="main-header" className={clsx('z-50 w-full', { 'menu-sticky': !props.notSticky, 'white-theme' : props.isWhiteTheme })}>
      <LogoJsonLd
        logo={props.menu.attributes?.logo?.data?.attributes?.url || ""}
        url="http://www.witopia.com"
      />
        <div className='menu-wrap relative hidden lg:block'>
          <div className='container'>
            <div className='flex items-center justify-between'>
              <div className='relative block logo-block'>
                <a href='/' title='WiTopia'>
                  <ResponsiveImage image={props.menu.attributes?.logo} />
                </a>
              </div>
              <nav className={clsx('main-menu mr-auto ml-[50px] transition-all')}>
                <ul className='mt-[26px] flex flex-wrap items-center justify-center space-x-8 text-base left-menu'>
                  {props.menu.attributes?.items?.map((item, index) => (
                    <li key={item?.id} className={item?.children?.length ? 'has-children' : ''}>
                      <a href={item?.href ?? undefined} className='active block py-5 font-roboto text-base font-medium'>
                        {item?.title}
                      </a>
                      {item?.children?.at(0) && (
                        <div className='sub-menu sub-menu-big invisible absolute left-1/2 mt-2.5 w-full opacity-0 transition-all duration-200 z-50 max-w-[1200px] -translate-x-1/2'>
                          <div className='container'>
                            <div className='rounded-[10px] bg-gradient-to-r from-[#FBFCFE] to-white shadow-[0_20px_40px_0px_rgba(0,0,0,0.3)]'>
                              <div className='grid grid-cols-3 gap-3'>
                                <div className='all-menu-wrap col-span-2 p-10'>
                                  <div className='mb-20 grid grid-cols-2 gap-10'>
                                    {item?.children?.map((child, index) => (
                                      <div key={child?.id} className=''>
                                        <h4 className='font-roboto text-lg font-bold'>{child?.title}</h4>
                                        <ul className='icon-with-menu'>
                                          {child?.links?.map((link, index) => (
                                            <li key={link?.id}>
                                              <a href={link?.href ?? undefined} className={`${link?.classNames ?? ''} font-roboto text-lg font-normal text-body-color hover:bg-[#f6f6ff] p-2`}>
                                                {link?.icon?.data && <div className='item-icon-name mr-5 w-[32px] h-[32px]'><ResponsiveImage className='w-full h-full' image={link?.icon} /></div>}
                                                <div className='title-wrap'>
                                                  <div className='menu-title'><span>{link?.title}</span></div>
                                                  {link?.subTitle && <div className='menu-subtitle text-sm'>{link?.subTitle}</div>}
                                                </div>
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                  {item?.footer && (
                                  <>
                                    <div className='mega-menu-footer'>
                                      <h4 className='font-roboto text-lg font-bold'>{item.footer.title}</h4>
                                      <div className='flex flex-wrap'>
                                        {item.footer?.items && (
                                          <ul className='flex flex-wrap items-center personalvpn'>
                                            {item.footer?.items.map((link) => (
                                              <li 
                                                key={link?.id}
                                                className='mr-1 xl:mr-4 h-[36px]'
                                                title={link?.title || ''}
                                              >
                                                {(link?.href !== '#' || link?.href.length > 1) ? (
                                                  <a href={link?.href ?? undefined} className='h-[36px]'>
                                                    <ResponsiveImage className='h-full w-auto' image={link?.icon} />
                                                  </a>
                                                ): (
                                                    <span className='h-[36px]'>
                                                      <ResponsiveImage className='h-full w-auto' image={link?.icon} />
                                                    </span>
                                                )}
                                              </li>
                                            ))}
                                            <li>
                                              <ul className='bottom-menu flex flex-col gap-y-4'>
                                                {item?.buttons?.map((button, index) => (
                                                <li key={button?.id}>
                                                  <a href={button?.href ?? undefined} className='font-roboto text-base font-semibold text-light-purple hover:underline'>
                                                    {button?.label}
                                                  </a>
                                                </li>
                                                ))}
                                              </ul>
                                            </li>
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                  )}                                  
                                </div>
                                <div className='all-article col-span-1 border-l bg-[#f6f6ff] border-[#E5ECF0] p-10 rounded-r-[10px]'>
                                  <ul className='grid h-full grid-cols-1 gap-x-5'>
                                    {item?.posts?.map((post, index) => (
                                      <li key={post?.id} className='flex h-full flex-col mb-5'>
                                        <div className='mb-5'>
                                          <div className='article-img relative mb-8 pt-[60%]'>
                                            <ResponsiveImage
                                              className='absolute left-0 top-0 right-0 bottom-0 h-full w-full rounded-[10px] object-cover'
                                              image={post?.post?.data?.attributes?.featureImage}
                                            />
                                          </div>
                                          <a href={`/blog/${post?.post?.data?.attributes?.slug}`} className='block font-roboto text-2xl font-normal text-title-color'>
                                            {post?.title}
                                          </a>
                                        </div>
                                        {post?.linkLabel &&
                                          <ul className='bottom-menu flex flex-col gap-y-4'>
                                            <li>
                                              <a href={post?.linkHref ?? undefined} className='font-roboto text-base font-semibold text-light-purple hover:underline'>
                                                {post?.linkLabel}
                                              </a>
                                            </li>
                                          </ul>
                                        }
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
              <div className='rightBlock inline-flex items-center'>
                <ul className={clsx('mt-[22px] flex flex-wrap items-center justify-center space-x-5 text-base right-menu')}>
                  {!props.hideLocales && <LanguageSwitcher />}
                  <ProductSignInWidget />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu menu={props.menu} hideLocales={props.hideLocales} isWhiteTheme={props.isWhiteTheme} />
      </header>
    </Sticky>
  )
}
